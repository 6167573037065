import * as Sentry from "@sentry/nuxt";
import { version } from "./package.json";

const {
  public: { sentryDsn },
} = useRuntimeConfig();

Sentry.init({
  enabled: process.env.NODE_ENV === "production",
  dsn: process.env.NODE_ENV === "production" ? sentryDsn : "",
  environment: process.env.NODE_ENV || "development",
  release: version,

  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 1.0,
  // replaysOnErrorSampleRate: 1.0,

  integrations: [
    Sentry.feedbackIntegration({
      colorScheme: "light",
      autoInject: false,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
});
